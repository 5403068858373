import { openDialog } from '@/components/popup/popup'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import { readLiveAddress } from '@/modules/membership/member.api'
import useDelay from 'common/hooks/useDelay'
import { shallowRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const CODES = {
  LEVEL_NOT_MATCH: '900003',
}

const useLiveRoom = () => {
  const router = useRouter()
  const route = useRoute()
  // 试看两分钟
  const after2Minutes = useDelay(1000 * 60 * 2)

  const liveReady = shallowRef(false)
  const liveAddress = shallowRef('')

  const exit = () => {
    router.back()
  }

  const exitAfter2Minutes = after2Minutes(() => {
    liveAddress.value = ''

    openDialog(LimitDialogTemplate, {
      i18nPath: 'live_19',
      onConfirm: exit,
    })
  })

  readLiveAddress({ channelId: route.params.liveId })
    .then(resp => {
      console.log(resp)
      if (resp.hlsDownstreamAddress) {
        liveAddress.value = resp.hlsDownstreamAddress
        if (!resp.isAuth) { // 试看
          openDialog(LimitDialogTemplate, { i18nPath: 'live_17' })
          exitAfter2Minutes()
        }
      }

      liveReady.value = true
    })
    .catch(e => {
      if (e.c === CODES.LEVEL_NOT_MATCH) {
        openDialog(LimitDialogTemplate, {
          // 根据用户等级选择不同的提示信息
          i18nPath: 'live_32',
          onConfirm: exit,
          onClose (isTrusted: boolean) {
            if (!isTrusted) exit()
          },
        })
      } else {
        exit()
      }
    })

  return {
    liveReady,
    liveAddress,
  }
}

export default useLiveRoom
