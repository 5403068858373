<template>
  <Page>
    <div class="fill df-col">
      <RatioFixedArea :ratio="48/375">
        <PageHeader class="live__header px-16">
          <template #title>
            <t path="membership_7">Live</t>
          </template>
          <template #right>
            <span class="d-ib v-middle f-md mr-4">
              {{online}}
            </span>
            <Icon name="user" sm style="color:white" />
          </template>
        </PageHeader>
      </RatioFixedArea>
      <RatioFixedArea :ratio="48/375">
        <TheLiveQuoteBar class="bg-text" :ready="liveReady" />
      </RatioFixedArea>
      <TheLiveVideo :address="liveAddress" />
      <div class="live-content flex-1 scroll-y p-16">
        <t as="p" path="live_44" class="c-text f-bold mt-8">Notice</t>
        <p class="mt-8">{{ tip }}</p>
        <t as="p" path="live_45" class="c-text f-bold mt-24">About the speaker</t>
        <div class="speaker mt-8">
          <img :src="authorUrl" alt="">
          <p class="desc f-sm c-title pl-12 py-8 pr-0">{{ authorDesc }}</p>
        </div>
      </div>
      <TheLiveOrderBtnsV2 />
    </div>
  </Page>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import PageHeader from '@/components/PageHeader.vue'
import TheLiveQuoteBar from '@/modules/membership/components/TheLiveQuoteBar.vue'
import TheLiveVideo from '@/modules/membership/components/TheLiveVideo.vue'
import useLiveRoom from '@/modules/membership/livev2'
import Page from '@/provider/Page.vue'
import RatioFixedArea from 'common/RatioFixedArea.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import TheLiveOrderBtnsV2 from '@/modules/membership/components/TheLiveOrderBtnsV2.vue'

export default defineComponent({
  name: 'Live',
  components: { Page, PageHeader, RatioFixedArea, TheLiveQuoteBar, TheLiveVideo, Icon, TheLiveOrderBtnsV2 },
  setup () {
    const route = useRoute()
    const { liveAddress, liveReady } = useLiveRoom()

    return {
      liveAddress,
      liveReady,
      online: route.query.user,
      tip: route.query.tip,
      authorUrl: route.query.authorUrl,
      authorDesc: route.query.authorDesc,
    }
  },
})
</script>

<style scoped lang="scss">
::v-deep(.live__header) {
  height: 100%;
  color: var(--color-white);
  background: var(--color-text);

  .icon {
    color: var(--color-title);
  }
}
.speaker{
  display: flex;
  align-items: end;
  background: var(--color-background);
  img{
    width: 100px;
  }
  .desc{
    height: 100%;
  }
}
</style>
