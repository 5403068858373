/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/27
* @description
*   TheLiveQuoteBar.vue of WeTrade
*/
<template>
  <div class="quote_bar">
    <HoldOn :content="list" height="40">
      <div v-for="item in list" :key="item.code" class="d-ib f-md pt-4 ml-16">
        <router-link :to="`/symbol/${item.code}`" >
          <RealTimeQuote :symbol="item.realTimeMicroQuotationBean" #="{margin, mp, isRise}">
            <p class="program">{{item.code}}</p>
            <p class="program c-success" :class="{'c-danger': !isRise}">{{margin}} <span>{{mp}}</span></p>
          </RealTimeQuote>
        </router-link>
      </div>
    </HoldOn>
  </div>
</template>

<script lang="ts">
import { MarketItem } from '@/modules/market/market.types'
import HoldOn from '@/provider/HoldOn.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import * as R from 'ramda'
import datafeed from '@/common/datafeed'
import { readWatchlist } from '@/modules/market/market.api'
import { defineComponent, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'TheLiveQuoteBar',
  components: { HoldOn, RealTimeQuote },
  props: {
    ready: Boolean,
  },
  setup (props) {
    const list = shallowRef<Array<MarketItem>>([])

    watchEffect(() => {
      if (props.ready) {
        readWatchlist().then(resp => {
          list.value = resp
          datafeed.subscribe(R.pluck('code', resp))
        })
      }
    })

    return {
      list,
    }
  },
})
</script>

<style scoped lang="scss">
.quote_bar {
  height: 100%;
  color: var(--color-tip);
  // background: var(--color-text);
  white-space: nowrap;
  overflow-x: auto;
}

.program {
  line-height: 22px;
}
</style>
