
import { TradeDirection } from '@/types'
import { defineComponent } from 'vue'
import { openDialog } from '@/components/popup/popup'
import TheLiveOrderDialog from '@/modules/membership/components/TheLiveOrderDialog.vue'
import Pic from '@/components/Pic.vue'
import Button from '@/components/Button.vue'
import router from '@/router'

export default defineComponent({
  name: 'TheLiveOrderBtns',
  components: {
    Pic,
    Button,
  },
  setup () {
    const dynamicSetting = {
      [TradeDirection.BUY]: {
        label: 'symbol_22',
        themeClass: 'success',
        labelDefault: 'BUY',
      },
      [TradeDirection.SELL]: {
        label: 'symbol_23',
        themeClass: 'danger',
        labelDefault: 'SELL',
      },
    }

    const orderClick = (type: TradeDirection) => {
      openDialog(TheLiveOrderDialog, {
        type,
        themeClass: dynamicSetting[type].themeClass,
      })
    }

    const handleLink = () => {
      router.push('/trade/fast')
    }

    return {
      TradeDirection,
      orderClick,
      handleLink,
    }
  },
})
