<template>
  <div class="oder-btn f-bold df-middle">
    <div class="fast-btn df-middle df-center" @click="handleLink">
      <Pic src="trade/quickplay" width="24" height="24" />
    </div>
    <Button icon="up" class="btn-item flex-1 btn success" @click="orderClick(TradeDirection.BUY)">
      <t as="p" path="live_35">Buy</t>
    </Button>
    <Button icon="down" class="btn-item flex-1 btn danger" @click="orderClick(TradeDirection.SELL)">
      <t as="p" path="live_36">Sell</t>
    </Button>
  </div>
</template>

<script lang="ts">
import { TradeDirection } from '@/types'
import { defineComponent } from 'vue'
import { openDialog } from '@/components/popup/popup'
import TheLiveOrderDialog from '@/modules/membership/components/TheLiveOrderDialog.vue'
import Pic from '@/components/Pic.vue'
import Button from '@/components/Button.vue'
import router from '@/router'

export default defineComponent({
  name: 'TheLiveOrderBtns',
  components: {
    Pic,
    Button,
  },
  setup () {
    const dynamicSetting = {
      [TradeDirection.BUY]: {
        label: 'symbol_22',
        themeClass: 'success',
        labelDefault: 'BUY',
      },
      [TradeDirection.SELL]: {
        label: 'symbol_23',
        themeClass: 'danger',
        labelDefault: 'SELL',
      },
    }

    const orderClick = (type: TradeDirection) => {
      openDialog(TheLiveOrderDialog, {
        type,
        themeClass: dynamicSetting[type].themeClass,
      })
    }

    const handleLink = () => {
      router.push('/trade/fast')
    }

    return {
      TradeDirection,
      orderClick,
      handleLink,
    }
  },
})
</script>

<style scoped lang="scss">
.oder-btn{
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
}
.fast-btn{
  height: 56px;
  min-width: 56px;
  max-width: 56px;
  color: var(--color-text);
  background: var(--color-background);
}
.btn-item{
  height: 56px;
}
</style>
