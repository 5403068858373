<template>
  <DialogTemplate @cancel="$emit('close')">
    <template #title>
      <t :path="type===1?'live_38':'live_37'">{{ type===1?'Sell':'Buy' }}</t>
    </template>
    <div class="flex-1 pt-16 pb-32">
      <HoldOn :content="products" :height="190">
        <div class="quote df-middle df-between pl-16 pr-8 br-lg" @click="openSelect">
          <TheLiveOrderQuoteBar :code="productCode" />
          <Icon name="caret-down" class="f-xl" />
        </div>

        <Matrix #="{ item }" :list="products" :identify="v => v.choiceId" class="mt-16">
          <ChoiceButton
            :class="['t-center', { active: item.choiceId === choice.choiceId }]"
            @click="select(item)"
          >
            <money :v="item.cost" class="f-bold" style="line-height: 28px" />
          </ChoiceButton>
        </Matrix>

        <div class="order-info mt-8 f-md">
          <div class="df-middle">
            <p class="flex-1 df-middle">
              <t path="live_39" class="c-tip mr-8">Commission</t>
              <money as="p" class="c-primary" :v="prop('sxf', choice)" />
            </p>
            <p class="flex-1 df-middle">
              <t path="live_40" class="c-tip mr-8">Total</t>
              <money as="p" class="c-primary" :v="totalPrice" />
            </p>
          </div>
        </div>
        <p class="mt-8 f-md df-middle">
          <t path="live_41" class="c-tip mr-8">Free margin</t>
          <TheAsset #="{freeMargin}">
            <money class="df-middle" :v="freeMargin" />
          </TheAsset>
        </p>
      </HoldOn>
    </div>
    <template #confirm>
      <Button class="block" :class="['flex-2', themeClass]" @click="create" :progress="progress">
        <t path="live_43" #="{td}" custom>{{ td || 'Place order' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'
import HoldOn from '@/provider/HoldOn.vue'
import Matrix from 'common/Matrix.vue'
import ChoiceButton from '@/components/ChoiceButton.vue'
import TradeModel from '@/modules/trade/TradeModel'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import { openFullscreenList } from '@/components/popup/popup'
import TheAssetSelect from '@/modules/membership/components/TheAssetSelect.vue'
import { Product } from '@/modules/trade/trade.api'
import { YesOrNo } from '@/types'
import { createPositionOrder, getProductsByCode } from '@/modules/trade/trade'
import TheLiveOrderQuoteBar from '@/modules/membership/components/TheLiveOrderQuoteBar.vue'
import { add } from 'essential/tools/math'
import { readMarketList } from '@/modules/market/market.api'

export default defineComponent({
  name: 'TheLiveOrderDialog',
  components: {
    Button,
    DialogTemplate,
    Matrix,
    ChoiceButton,
    HoldOn,
    Icon,
    TheLiveOrderQuoteBar,
    TheAsset,
  },
  emits: ['close'],
  props: {
    type: {
      type: Number,
      required: true,
    },
    themeClass: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const model = new TradeModel(props.type)
    const products = shallowRef()
    const choice = shallowRef()
    const totalPrice = shallowRef(0)
    const productCode = shallowRef()

    const reLoadProduct = (code: string, isOften = YesOrNo.YES) => {
      getProductsByCode(code, isOften).then((res: Product[]) => {
        products.value = res
        choice.value = res[0]
        totalPrice.value = add(res[0].cost, res[0].sxf)
        model.updateProduct(res[0])
      })
    }

    readMarketList().then(res => {
      productCode.value = res[0].code
      reLoadProduct(res[0].code)
    })

    const select = (item: Product) => {
      choice.value = item
      totalPrice.value = add(item.cost, item.sxf)
      model.updateProduct(item)
    }

    const openSelect = () => {
      openFullscreenList(TheAssetSelect, {
        onChange: (code: string) => {
          productCode.value = code
          reLoadProduct(code)
        },
      })
    }

    const [create, progress] = createPositionOrder(model)

    return {
      products,
      productCode,
      choice,
      totalPrice,
      create,
      progress,
      select,
      openSelect,
    }
  },
})
</script>

<style scoped lang="scss">
.quote{
  background-color: var(--color-light);
  height: 40px;
}
</style>
