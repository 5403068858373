
import { MarketItem } from '@/modules/market/market.types'
import HoldOn from '@/provider/HoldOn.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import * as R from 'ramda'
import datafeed from '@/common/datafeed'
import { readWatchlist } from '@/modules/market/market.api'
import { defineComponent, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'TheLiveQuoteBar',
  components: { HoldOn, RealTimeQuote },
  props: {
    ready: Boolean,
  },
  setup (props) {
    const list = shallowRef<Array<MarketItem>>([])

    watchEffect(() => {
      if (props.ready) {
        readWatchlist().then(resp => {
          list.value = resp
          datafeed.subscribe(R.pluck('code', resp))
        })
      }
    })

    return {
      list,
    }
  },
})
