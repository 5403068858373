
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import SymbolList from '@/modules/market/SymbolList.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheAssetSelect',
  components: { PageHeader, FullScreen, SymbolList, Scroller },
  emits: ['close', 'change'],
  setup (props, ctx) {
    const select = (code: string) => {
      ctx.emit('change', code)
      ctx.emit('close')
    }
    return {
      select,
    }
  },
})
