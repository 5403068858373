
import Icon from '@/components/Icon.vue'
import PageHeader from '@/components/PageHeader.vue'
import TheLiveQuoteBar from '@/modules/membership/components/TheLiveQuoteBar.vue'
import TheLiveVideo from '@/modules/membership/components/TheLiveVideo.vue'
import useLiveRoom from '@/modules/membership/livev2'
import Page from '@/provider/Page.vue'
import RatioFixedArea from 'common/RatioFixedArea.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import TheLiveOrderBtnsV2 from '@/modules/membership/components/TheLiveOrderBtnsV2.vue'

export default defineComponent({
  name: 'Live',
  components: { Page, PageHeader, RatioFixedArea, TheLiveQuoteBar, TheLiveVideo, Icon, TheLiveOrderBtnsV2 },
  setup () {
    const route = useRoute()
    const { liveAddress, liveReady } = useLiveRoom()

    return {
      liveAddress,
      liveReady,
      online: route.query.user,
      tip: route.query.tip,
      authorUrl: route.query.authorUrl,
      authorDesc: route.query.authorDesc,
    }
  },
})
