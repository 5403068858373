
import 'video.js/dist/video-js.min.css'
import { stopVideo } from '@/common/jsBridge.api'
import Pic from '@/components/Pic.vue'
import { defineComponent, nextTick, onBeforeUnmount, shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import videojs, { VideoJsPlayer } from 'video.js'
import canAutoplay from 'can-autoplay'

export default defineComponent({
  name: 'TheLiveVideo',
  components: { Pic },
  props: {
    address: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const video = shallowRef()
    const playing = shallowRef(false)
    const { poster, time, desc } = useRoute().query
    let player: VideoJsPlayer | null = null
    const playPromise = shallowRef()

    watch(() => props.address, (val) => {
      if (!val) {
        stopVideo()
        playing.value = false
      }
    })

    const play = () => {
      playing.value = true
      nextTick(() => {
        player = videojs(video.value, {
          controls: true,
          autoplay: true,
          fluid: true,
          bigPlayButton: false,
          sources: [{
            src: props.address,
            type: 'application/x-mpegURL',
          }],
        }, function () {
          canAutoplay.video().then(resp => {
            if (!resp.result) {
              this.play()
            }
          })
          player?.bigPlayButton?.hide()
          player?.ready(() => {
            playPromise.value = player?.play()
          })
        })
      })
    }

    const stop = () => {
      if (video.value) {
        (video.value as HTMLVideoElement).pause()
      }
    }

    onBeforeUnmount(() => {
      if (playPromise.value !== undefined) {
        playPromise.value.then(() => {
          player?.pause()
          player?.dispose()
          stop()
        })
      }
    })

    return {
      video,
      poster,
      time,
      desc,
      playing,
      play,
    }
  },
})
