/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/27
* @description
*   TheLiveVideo.vue of WeTrade
*/
<template>
  <div class="video-holder">
    <div class="spread">
      <div class="fill df-middle">
        <video webkit-playsinline playsinline v-if="playing" ref="video" class="video-js"></video>
        <template v-else>
          <Pic :src="poster" class="spread fill" />
          <div class="fill">
            <!-- <div class="desc">
              <p class="f-bold mb-12" style="color: white">{{time}}</p>
              <p class="c-title">{{desc}}</p>
            </div> -->
            <button v-if="address" class="watch df-middle df-center" @click="play">
              <t path="live_3">Watch Live</t>
              <Pic class="ml-8" src="membership/play" width="24" height="24" />
            </button>
            <div v-else class="end-style">
              <t as="p" path="live_46" class="c-white t-center f-lg f-bold">Live streaming is ended</t>
              <p class="c-white t-center mt-12"><t path="live_47"></t> {{ time }}</p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import 'video.js/dist/video-js.min.css'
import { stopVideo } from '@/common/jsBridge.api'
import Pic from '@/components/Pic.vue'
import { defineComponent, nextTick, onBeforeUnmount, shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import videojs, { VideoJsPlayer } from 'video.js'
import canAutoplay from 'can-autoplay'

export default defineComponent({
  name: 'TheLiveVideo',
  components: { Pic },
  props: {
    address: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const video = shallowRef()
    const playing = shallowRef(false)
    const { poster, time, desc } = useRoute().query
    let player: VideoJsPlayer | null = null
    const playPromise = shallowRef()

    watch(() => props.address, (val) => {
      if (!val) {
        stopVideo()
        playing.value = false
      }
    })

    const play = () => {
      playing.value = true
      nextTick(() => {
        player = videojs(video.value, {
          controls: true,
          autoplay: true,
          fluid: true,
          bigPlayButton: false,
          sources: [{
            src: props.address,
            type: 'application/x-mpegURL',
          }],
        }, function () {
          canAutoplay.video().then(resp => {
            if (!resp.result) {
              this.play()
            }
          })
          player?.bigPlayButton?.hide()
          player?.ready(() => {
            playPromise.value = player?.play()
          })
        })
      })
    }

    const stop = () => {
      if (video.value) {
        (video.value as HTMLVideoElement).pause()
      }
    }

    onBeforeUnmount(() => {
      if (playPromise.value !== undefined) {
        playPromise.value.then(() => {
          player?.pause()
          player?.dispose()
          stop()
        })
      }
    })

    return {
      video,
      poster,
      time,
      desc,
      playing,
      play,
    }
  },
})
</script>

<style scoped lang="scss">
.video-holder {
  position: relative;
  padding-top: 56.25%;
  // background: var(--color-text);
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.desc {
  max-height: 60%;
  font-size: 13px;
  padding: 20px 20px 20px 40%;
}

.watch {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 48px;
  border: none;
  border-radius: 8px;
  padding: 0 12px;
  color: var(--color-white);
  font-weight: bold;
  background: var(--color-primary);
}

.end-style{
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 60px;
}
</style>
