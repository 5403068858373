/**
* @author zjc[beica1@outook.com]
* @date 2021/11/18 14:19
* @description
*   QuoteBar.vue of WeTrade
*/

<template>
<div>
  <CurrentQuote :code="code" #="{symbol}">
    <RealTimeQuote :symbol="symbol" #="synced">
      <div
        class="bar df-middle t-center"
        v-bind="$attrs"
        @click="navigate"
      >
        <slot v-bind="synced">
          <div class="flex-3 t-left df-middle">
            <span class="f-bold c-text mr-12">{{synced.code}}</span>
            <span>{{synced.buy}}</span>
            <span class="diff c-text mx-8">{{synced.spread}}</span>
            <span>{{synced.price}}</span>
          </div>
        </slot>
      </div>
    </RealTimeQuote>
  </CurrentQuote>
</div>
</template>

<script lang="ts">
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { defineComponent, watchEffect, onBeforeUnmount } from 'vue'
import datafeed from '@/common/datafeed'

export default defineComponent({
  name: 'LiveOrderQuoteBar',
  components: { CurrentQuote, RealTimeQuote },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    watchEffect(() => {
      datafeed.unSubscribe([], true)
      datafeed.subscribe([props.code], true)
    })
    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })
  },
})
</script>

<style scoped>
.bar {
  height: 32px;
}

.diff {
  padding: 0 1px;
  font-size: 11px;
  border: 1px solid var(--color-title-80);
  border-radius: 2px;
}
</style>
