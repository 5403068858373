
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'
import HoldOn from '@/provider/HoldOn.vue'
import Matrix from 'common/Matrix.vue'
import ChoiceButton from '@/components/ChoiceButton.vue'
import TradeModel from '@/modules/trade/TradeModel'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import { openFullscreenList } from '@/components/popup/popup'
import TheAssetSelect from '@/modules/membership/components/TheAssetSelect.vue'
import { Product } from '@/modules/trade/trade.api'
import { YesOrNo } from '@/types'
import { createPositionOrder, getProductsByCode } from '@/modules/trade/trade'
import TheLiveOrderQuoteBar from '@/modules/membership/components/TheLiveOrderQuoteBar.vue'
import { add } from 'essential/tools/math'
import { readMarketList } from '@/modules/market/market.api'

export default defineComponent({
  name: 'TheLiveOrderDialog',
  components: {
    Button,
    DialogTemplate,
    Matrix,
    ChoiceButton,
    HoldOn,
    Icon,
    TheLiveOrderQuoteBar,
    TheAsset,
  },
  emits: ['close'],
  props: {
    type: {
      type: Number,
      required: true,
    },
    themeClass: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const model = new TradeModel(props.type)
    const products = shallowRef()
    const choice = shallowRef()
    const totalPrice = shallowRef(0)
    const productCode = shallowRef()

    const reLoadProduct = (code: string, isOften = YesOrNo.YES) => {
      getProductsByCode(code, isOften).then((res: Product[]) => {
        products.value = res
        choice.value = res[0]
        totalPrice.value = add(res[0].cost, res[0].sxf)
        model.updateProduct(res[0])
      })
    }

    readMarketList().then(res => {
      productCode.value = res[0].code
      reLoadProduct(res[0].code)
    })

    const select = (item: Product) => {
      choice.value = item
      totalPrice.value = add(item.cost, item.sxf)
      model.updateProduct(item)
    }

    const openSelect = () => {
      openFullscreenList(TheAssetSelect, {
        onChange: (code: string) => {
          productCode.value = code
          reLoadProduct(code)
        },
      })
    }

    const [create, progress] = createPositionOrder(model)

    return {
      products,
      productCode,
      choice,
      totalPrice,
      create,
      progress,
      select,
      openSelect,
    }
  },
})
