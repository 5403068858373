<template>
  <FullScreen class="df-col">
    <PageHeader class="px-16">
      <template #title>
        <t path="selectasset_2">Select Asset</t>
      </template>
    </PageHeader>
    <Scroller css-only class="flex-1 px-16">
      <SymbolList #="{symbol}">
        <div class="asset-item br-lg pl-16 df-col df-center mb-8" @click="select(symbol.code)">
          <p class="c-text f-400">{{ symbol.code }}</p>
          <p class="f-xs c-tip">{{ symbol.tips }}</p>
        </div>
      </SymbolList>
    </Scroller>
  </FullScreen>
</template>

<script lang="ts">
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import SymbolList from '@/modules/market/SymbolList.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheAssetSelect',
  components: { PageHeader, FullScreen, SymbolList, Scroller },
  emits: ['close', 'change'],
  setup (props, ctx) {
    const select = (code: string) => {
      ctx.emit('change', code)
      ctx.emit('close')
    }
    return {
      select,
    }
  },
})
</script>

<style scoped lang="scss">
.asset-item{
  border: 1px solid var(--color-border);
  height: 48px;
}
</style>
