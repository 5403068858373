
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { defineComponent, watchEffect, onBeforeUnmount } from 'vue'
import datafeed from '@/common/datafeed'

export default defineComponent({
  name: 'LiveOrderQuoteBar',
  components: { CurrentQuote, RealTimeQuote },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    watchEffect(() => {
      datafeed.unSubscribe([], true)
      datafeed.subscribe([props.code], true)
    })
    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })
  },
})
